import React from "react";
import Navbar from "../../components/Navbar/Navbar";

const Academy = () => {
  return (
    <div>
      <div>
        <Navbar />
        <div>Our academy page</div>
      </div>
    </div>
  );
};

export default Academy;
